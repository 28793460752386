import axios from "axios";

// const BASE_URL = "http://localhost:8000";
const BASE_URL = "https://csuccoth-backend.vasesdhonneur.org";

export default {
  signData: async function(data) {

    const signedData = await axios
      .post(`${BASE_URL}/sign-data`, data, {
        headers: {
          "Content-Type": "application/json",
          'Authorization' : 'Basic YWRtaW46RGV3aW5kOTEh',
          // 'X-CSRF-Token'  : csrfToken.data
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        this.handleAxiosError(error);
      });

    return signedData;
  },
  addUser: async function(data) {

    const addUser = await axios
      .post(`${BASE_URL}/api/users`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46RGV3aW5kOTEh"
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });

    return addUser;
  },
  createPayment: async function(data) {

    const createPayment = await axios
      .post(`${BASE_URL}/api/payments`, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Basic YWRtaW46RGV3aW5kOTEh"
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error;
      });

    return createPayment;
  },
  verifyAuth: async function(email) {
    const createPayment = await axios
      .get(`${BASE_URL}/api/user/${email}`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error;
      });

    return createPayment;
  },
  logUser: async function(data) {

    const logUser = await axios
      // .post(`${BASE_URL}/api/login_check`, data, {
      .post(`${BASE_URL}/api/custom-login`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });

    return logUser;
  },
  passwordReset: async function(data) {
    const passwordReset = await axios
      // .post(`${BASE_URL}/api/login_check`, data, {
      .post(`${BASE_URL}/api/user/change-password`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });

    return passwordReset;
  },
  logout: async function(data) {
    const logOutUser = await axios
      .post(`${BASE_URL}/api/custom-logout`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error.response;
      });

    return logOutUser;
  },
  getAllSessions: async function() {
    return await axios
      .get(`${BASE_URL}/api/sessions`, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error;
      });
  },
  sendSms: async function(data) {
    return await axios
      .post(`${BASE_URL}/api/send-sms`, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then(function(response) {
        return response;
      })
      .catch(function(error) {
        return error;
      });
  }
};
