// import ApiService from "@/core/services/api.service";
import API from "@/core/services/api";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const LOGIN_INFORMATION = "loginInformation";

const state = {
  errors: null,
  user: {},
  isAuthenticated: !!JwtService.getToken(),
  loginInformation: null
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  loginInformation(state) {
    return state.loginInformation;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    credentials.username = credentials.email;
    return new Promise(resolve => {
      API.logUser(credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  /* [REGISTER](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("users", { user: credentials })
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
          reject(response);
        });
    });
  }, */
  [VERIFY_AUTH](context, userEmail) {
    if (JwtService.getToken()) {
      API.verifyAuth(userEmail.userEmail)
        .then(({ data }) => {

	  /* if (data.agent !== window.navigator.userAgent) {
            context.commit(PURGE_AUTH);
            window.location.href = "/#/login";
          }*/
          context.commit(SET_AUTH, data);
        })
        .catch(() => {
          const currentUri = window.location.href.split("/");
          if (["dashboard",
            "all-sessions"].includes(currentUri[currentUri.length - 1])
          )
            window.location.href = "/#/login";
        });
    } else {
      context.commit(PURGE_AUTH);
    }
  }
  /* [UPDATE_USER](context, payload) {
    const { email, username, password, image, bio } = payload;
    const user = { email, username, bio, image };
    if (password) {
      user.password = password;
    }

    return ApiService.put("user", user).then(({ data }) => {
      context.commit(SET_AUTH, data);
      return data;
    });
  } */
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [LOGIN_INFORMATION](state, data) {
    state.loginInformation = data.email;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
